@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$colorPrimary: #2df4a1;
$black: #000;
$white: #fff;
$red: #de484c;
$green: #51b392;

$font-family-Anton: 'Anton', sans-serif !important;
$font-family-Montserrat: 'Montserrat', sans-serif !important;
