@import 'variable.scss';

header {
  .navbar {
    height: 5rem;
    border-bottom: 1px solid #ffffff20;

    .navbar-brand {
      letter-spacing: 1.5px;
      font-size: 2rem;
      color: #fff !important;
    }
    .navbar-nav {
      .nav-link.active,
      .nav-link.show {
        color: $colorPrimary !important;
      }

      .nav-link {
        font-size: 1rem;
        color: #fff !important;
        &:hover {
          color: $colorPrimary !important;
        }
      }
    }

    .chat-icon {
      right: -20px;
    }
  }
}
