@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
header .navbar {
  height: 5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1254901961);
}
header .navbar .navbar-brand {
  letter-spacing: 1.5px;
  font-size: 2rem;
  color: #fff !important;
}
header .navbar .navbar-nav .nav-link.active,
header .navbar .navbar-nav .nav-link.show {
  color: #2df4a1 !important;
}
header .navbar .navbar-nav .nav-link {
  font-size: 1rem;
  color: #fff !important;
}
header .navbar .navbar-nav .nav-link:hover {
  color: #2df4a1 !important;
}
header .navbar .chat-icon {
  right: -20px;
} 