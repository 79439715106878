@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
section.hero-section {
  background-image: url('../../assets/images/hero_section_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
section.impact-section {
  background-image: url('../../assets/images/map.svg'),
    url('../../assets/images/roadmap-backdrop.svg');
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
}
section.impact-section .list-group-item {
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #2df4a1;
  border-radius: 0;
}
section.blog-section {
  background-image: url('../../assets/images/hero_section_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
section.channel-section .card-img-overlay {
  background-color: rgba(0, 0, 0, 0.2);
}
section.roadmap-section {
  background-image: url('../../assets/images/roadmap-backdrop.svg');
  background-size: cover;
  background-repeat: no-repeat;
  /* Hide the ::after pseudo-element of the third .card in each row */
}
section.roadmap-section .last-child ::before {
  display: none;
}
section.roadmap-section .card .card-img {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
}
section.roadmap-section .card::before {
  content: '';
  position: absolute;
  top: 25px;
  left: -40px;
  transform: translateX(-50%);
  width: 7px;
  height: 100%;
  background: linear-gradient(270deg, #2df4a1 0%, #a45cff 100%);
}
section.roadmap-section .card::after {
  content: '';
  position: absolute;
  top: 15px;
  left: -40px;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  background: url(../../assets/icons/roadmap-circle.svg);
  border-radius: 50%;
}
section.contact-section {
  background-image: url('../../assets/images/footer-backdrop.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
section.contact-section a:hover {
  color: #2df4a1;
}
section.contact-section a {
  color: #fff;
}
section.contact-section a {
  text-decoration: none !important;
}
section.contact-section ul {
  list-style-type: none;
}
section.contact-section ul.footer-links.links li {
  position: relative;
  margin: 0.625rem 0.625rem 0.625rem 2rem;
}
section.contact-section ul.footer-links.links li::before {
  content: '';
  width: 2.5rem;
  height: 2.5rem;
  background: url(../../assets/icons/tick-icon.svg);
  position: absolute;
  background-repeat: no-repeat;
  left: -2rem;
}
section.contact-section ul.footer-links.mail-links li {
  position: relative;
  margin: 0.625rem 0.625rem 0.625rem 2rem;
}
section.contact-section ul.footer-links.mail-links li::before {
  content: '';
  width: 2.5rem;
  height: 2.5rem;
  background: url(../../assets/icons/mail-icon.svg);
  position: absolute;
  background-repeat: no-repeat;
  left: -2rem;
} 