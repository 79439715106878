// Import variables
@import 'variable.scss';

// Base styles
body {
  font-size: 16px;
  color: $white !important;
  background-color: $black !important;
  font-family: $font-family-Montserrat;
}

// Sections CSS
section {
  margin-block-start: -6rem;
  padding: 3rem 0rem 9rem 0rem;
  border-radius: 6rem 6rem 0 0;

  &:first-of-type {
    padding-block-start: 9rem;
    border-radius: 0;
  }
  &:last-of-type {
    margin-block-end: -5rem;
    border-radius: 0;
  }
  // Custom Colors For Sections
  &.primary {
    color: $white;
    background-color: $black;
  }

  &.secondary {
    color: $black;
    background-color: $white;
  }

  &.tertiary {
    color: $black;
    background-color: #f1ece7;
  }
}

.font-brand {
  font-family: $font-family-Anton;
  text-transform: uppercase;
}

.line-0 {
  line-height: 1;
}

// // // Type Headings
.custom {
  font-size: calc(16px + 2.2vw) !important;
  line-height: calc(16px + 2.2vw) !important;
}
.hero-title {
  font-size: calc(16px + 4.5vw);
  line-height: calc(16px + 4.5vw);
  font-family: $font-family-Anton;
  text-transform: uppercase;
}

.hero-title-large {
  font-family: $font-family-Anton;
  text-transform: uppercase;
  font-size: calc(16px + 10vw);
  line-height: calc(16px + 10vw);
}

.hero-title-medium {
  font-family: $font-family-Anton;
  text-transform: uppercase;
  font-size: calc(16px + 3vw);
  line-height: calc(16px + 3vw);
}

.small-line-height {
  line-height: calc(16px + 1vw) !important;
}

.section-title {
  font-size: calc(16px + 0.2vw);
  line-height: calc(16px + 1vw);
}

.stroke-heading {
  -webkit-text-stroke-width: calc(0.1px + 0.15vw);
  stroke-width: calc(0.1px + 0.1vw);
  font-family: $font-family-Anton;

  &.dark-storke {
    color: transparent;
    -webkit-text-stroke-color: $black;
    stroke: $black;
  }

  &.light-stroke {
    color: transparent;
    -webkit-text-stroke-color: $white;
    stroke: $white;
  }
}

// Buttons
.btn-lg {
  padding: 0.8rem 2rem !important;
}

.btn {
  font-family: $font-family-Anton;
  border-radius: 2px 2px 22px !important;
  text-transform: uppercase;

  &.btn-outline-light {
    background-image: radial-gradient(
      ellipse 172.5340546095px 49.3691539933px at 0px 0px,
      #fff6 0%,
      #fff0 100%
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: transparent !important;
    color: #fff;
    border-radius: 4px 4px 24px !important;
    padding: 10px 24px !important;
    border: 1px solid #ffffff50 !important;

    &:hover {
      color: $white;
      transition: 0.5s;
      border: 1px solid #000 !important;
    }
  }

  &.btn-bordered-light {
    color: $white;
    background-color: transparent !important;
    border: 1px solid $white !important;
  }

  &.btn-primary {
    color: $black !important;
    background-color: $colorPrimary !important;
    padding: 10px 24px !important;
    border-color: transparent;

    &:hover {
      color: $black;
      background: linear-gradient(90deg, $colorPrimary 0%, #a45cff 100%);
      transition: 0.5s;
    }
  }

  &.btn-outline-dark {
    &:hover {
      background-color: transparent;
      color: $black;
    }
  }
}

.btn-fill {
  text-decoration: none !important;
  font-family: 'Montserrat', sans-serif !important;
  text-align: start !important;
  width: 100%;
  padding: 16px 32px !important;
  background-image: url('../../assets/icons/chevron-right.svg');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;

  &:hover {
    color: $white !important;
    font-weight: 800;
    color: $black;
    background: linear-gradient(90deg, $colorPrimary 0%, #a45cff 100%);
  }
}

// Form elements
.form-select,
.form-control {
  min-height: 54px;
  border-color: $black !important;
}

.form-label {
  font-weight: 500;
}

.form-check-input {
  height: 2rem !important;
  width: 2rem !important;
  border-color: $black !important;

  &:checked {
    background-color: $colorPrimary !important;
    border-color: $colorPrimary !important;
  }
}

.form-check {
  .form-check-label {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}

// Scroller
.hr-scroller {
  height: 100px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  .mover-1 {
    height: 80px;
    width: max-content;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    animation: movescroller 5s linear infinite;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
      margin: 32px;
    }
  }
  @keyframes movescroller {
    100% {
      transform: translateX(-500px);
    }
  }
}

@media (max-width: 991px) {
  section {
    border-radius: 3rem 3rem 0 0;
  }
  .nav-bg {
    background-color: #000;
  }
}

.bg-transparent {
  background: transparent !important;
}

//Footer CC
footer {
  height: 5rem;
  background-color: transparent !important;
  border-top: 1px solid #ffffff20;

  .copy-text {
    font-size: 1rem;
    font-weight: 200;
  }
}

.icon-invert {
  filter: invert(1);
}

//Table View
.table {
  max-height: 25rem;
  overflow: auto;
  td {
    color: $black;
  }
  tr:nth-of-type(odd) td {
    background: rgba(246, 248, 249, 1) !important;
    --bs-table-bg-type: none !important;
  }
}

.table-user {
  width: 2.5rem;
  height: 2.5rem;
}

.global-user-thumb-large {
  height: 3rem;
  width: 3rem;
}
.badge {
  &.rounded-pill {
    font-weight: 400 !important;
    background-color: $white !important;
    padding: 8px 10px !important;

    &.red {
      border: 1px solid $red !important;
    }

    &.green {
      border: 1px solid $green !important;
    }
    &.blue {
      border: 1px solid #4198ff !important;
    }
  }
}

//Members Group Images Stack
.members-group-stack {
  .members-count {
    background-color: #fff;
    border: 1.3px solid #000;
    font-weight: 500;
  }
  img,
  .members-count {
    margin-right: -0.7rem;
  }
}

.offcanvas {
  &.offcanvas-end {
    width: 645px !important;
  }
}

.bg-grey {
  background-color: #f6f8f9 !important;
}

.text-title-ul::after {
  content: '';
  display: block;
  background-color: #51b392;
  height: 3px;
  width: 50px;
  border-radius: 20px;
}

@media (min-width: 0px) {
  .media-break {
    width: 100% !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 481px) {
  .media-break {
    width: 50% !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 768px) {
  .media-break {
    width: 33.3% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 992px) {
  .media-break {
    width: 20% !important;
  }
}

.custom-border {
  border: 1px solid #000 !important;
}

.tabs-wrapper {
  &.nav-tabs {
    border-bottom: 1px solid #eee !important;
    display: inline-flex;
    .nav-link {
      color: #fff !important;
      border: none !important;

      &.active {
        background-color: transparent !important;
        border: none !important;
        border-bottom: none;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background: linear-gradient(90deg, #2df4a1 0%, #6e22ff 100%);
        }
      }
    }
  }
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
.eye-icon-with-validation {
  right: 30px !important;
}

.loader-bg {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000;
  bottom: 0px;
}
