@import 'variable.scss';

section {
  &.hero-section {
    background-image: url('../../assets/images/hero_section_bg.svg');
    background-size: cover;
    background-repeat: no-repeat;

    .banner-image {
    }
  }
  &.impact-section {
    background-image: url('../../assets/images/map.svg'),
      url('../../assets/images/roadmap-backdrop.svg');
    background-size: cover, cover;
    background-repeat: no-repeat, no-repeat;

    .list-group-item {
      background-color: transparent;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-bottom: 1px solid $colorPrimary;
      border-radius: 0;
    }
  }
  &.blog-section {
    background-image: url('../../assets/images/hero_section_bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.channel-section {
    .card-img-overlay {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &.roadmap-section {
    background-image: url('../../assets/images/roadmap-backdrop.svg');
    background-size: cover;
    background-repeat: no-repeat;

    /* Hide the ::after pseudo-element of the third .card in each row */
    .last-child ::before {
      display: none;
    }

    .card {
      .card-img {
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
      }

      &::before {
        content: '';
        position: absolute;
        top: 25px;
        left: -40px;
        transform: translateX(-50%);
        width: 7px;
        height: 100%;
        background: linear-gradient(270deg, #2df4a1 0%, #a45cff 100%);
      }
      &::after {
        content: '';
        position: absolute;
        top: 15px;
        left: -40px;
        transform: translate(-50%, -50%);
        width: 42px;
        height: 42px;
        background: url(../../assets/icons/roadmap-circle.svg);
        border-radius: 50%;
      }
    }
  }

  &.contact-section {
    background-image: url('../../assets/images/footer-backdrop.svg');
    background-size: cover;
    background-repeat: no-repeat;

    a:hover {
      color: #2df4a1;
    }

    a {
      color: #fff;
    }

    a {
      text-decoration: none !important;
    }

    ul {
      list-style-type: none;

      &.footer-links {
        &.links {
          li {
            position: relative;
            margin: 0.625rem 0.625rem 0.625rem 2rem;
            &::before {
              content: '';
              width: 2.5rem;
              height: 2.5rem;
              background: url(../../assets/icons/tick-icon.svg);
              position: absolute;
              background-repeat: no-repeat;
              left: -2rem;
            }
          }
        }

        &.mail-links {
          li {
            position: relative;
            margin: 0.625rem 0.625rem 0.625rem 2rem;
            &::before {
              content: '';
              width: 2.5rem;
              height: 2.5rem;
              background: url(../../assets/icons/mail-icon.svg);
              position: absolute;
              background-repeat: no-repeat;
              left: -2rem;
            }
          }
        }
      }
    }
  }
}
